/* global settings */
/* holy grail */
/* breakpoints */
/**
 * Loader GIF
 */
/**
 * Breakpoints
 */
.rs-columns {
  clear: both;
  display: inline-block;
  width: 100%;
}

.rs-column {
  float: left;
  width: 100%;
  margin-top: 7.6923076923%;
  margin-right: 7.6923076923%;
}
.rs-column.-large-first {
  clear: left;
}
.rs-column.-large-last {
  margin-right: -5px;
}
.rs-column.-large-first-row {
  margin-top: 0;
}
.rs-column.-large-col-1-1 {
  width: 100%;
}
.rs-column.-large-col-2-1 {
  width: 46.1538461538%;
}
.rs-column.-large-col-2-2 {
  width: 100%;
}
.rs-column.-large-col-3-1 {
  width: 28.2051282051%;
}
.rs-column.-large-col-3-2 {
  width: 64.1025641026%;
}
.rs-column.-large-col-3-3 {
  width: 100%;
}
.rs-column.-large-col-4-1 {
  width: 19.2307692308%;
}
.rs-column.-large-col-4-2 {
  width: 46.1538461538%;
}
.rs-column.-large-col-4-3 {
  width: 73.0769230769%;
}
.rs-column.-large-col-4-4 {
  width: 100%;
}
.rs-column.-large-col-5-1 {
  width: 13.8461538462%;
}
.rs-column.-large-col-5-2 {
  width: 35.3846153846%;
}
.rs-column.-large-col-5-3 {
  width: 56.9230769231%;
}
.rs-column.-large-col-5-4 {
  width: 78.4615384615%;
}
.rs-column.-large-col-5-5 {
  width: 100%;
}
.rs-column.-large-col-6-1 {
  width: 10.2564102564%;
}
.rs-column.-large-col-6-2 {
  width: 28.2051282051%;
}
.rs-column.-large-col-6-3 {
  width: 46.1538461538%;
}
.rs-column.-large-col-6-4 {
  width: 64.1025641026%;
}
.rs-column.-large-col-6-5 {
  width: 82.0512820513%;
}
.rs-column.-large-col-6-6 {
  width: 100%;
}
@media screen and (max-width: 900px) {
  .rs-column {
    margin-top: 7.6923076923%;
    margin-right: 7.6923076923%;
  }
  .rs-column.-large-first {
    clear: none;
  }
  .rs-column.-large-last {
    margin-right: 7.6923076923%;
  }
  .rs-column.-large-first-row {
    margin-top: 7.6923076923%;
  }
  .rs-column.-medium-first {
    clear: left;
  }
  .rs-column.-medium-last {
    margin-right: -5px;
  }
  .rs-column.-medium-first-row {
    margin-top: 0;
  }
  .rs-column.-medium-col-1-1 {
    width: 100%;
  }
  .rs-column.-medium-col-2-1 {
    width: 46.1538461538%;
  }
  .rs-column.-medium-col-2-2 {
    width: 100%;
  }
  .rs-column.-medium-col-3-1 {
    width: 28.2051282051%;
  }
  .rs-column.-medium-col-3-2 {
    width: 64.1025641026%;
  }
  .rs-column.-medium-col-3-3 {
    width: 100%;
  }
  .rs-column.-medium-col-4-1 {
    width: 19.2307692308%;
  }
  .rs-column.-medium-col-4-2 {
    width: 46.1538461538%;
  }
  .rs-column.-medium-col-4-3 {
    width: 73.0769230769%;
  }
  .rs-column.-medium-col-4-4 {
    width: 100%;
  }
  .rs-column.-medium-col-5-1 {
    width: 13.8461538462%;
  }
  .rs-column.-medium-col-5-2 {
    width: 35.3846153846%;
  }
  .rs-column.-medium-col-5-3 {
    width: 56.9230769231%;
  }
  .rs-column.-medium-col-5-4 {
    width: 78.4615384615%;
  }
  .rs-column.-medium-col-5-5 {
    width: 100%;
  }
  .rs-column.-medium-col-6-1 {
    width: 10.2564102564%;
  }
  .rs-column.-medium-col-6-2 {
    width: 28.2051282051%;
  }
  .rs-column.-medium-col-6-3 {
    width: 46.1538461538%;
  }
  .rs-column.-medium-col-6-4 {
    width: 64.1025641026%;
  }
  .rs-column.-medium-col-6-5 {
    width: 82.0512820513%;
  }
  .rs-column.-medium-col-6-6 {
    width: 100%;
  }
}
@media screen and (max-width: 599px) {
  .rs-column {
    margin-right: 7.6923076923%;
  }
  .rs-column.-medium-first {
    clear: none;
  }
  .rs-column.-medium-last {
    margin-right: 7.6923076923%;
  }
  .rs-column.-medium-first-row {
    margin-top: 7.6923076923%;
  }
  .rs-column.-small-first {
    clear: left;
  }
  .rs-column.-small-last {
    margin-right: -5px;
  }
  .rs-column.-small-first-row {
    margin-top: 0;
  }
  .rs-column.-small-col-1-1 {
    width: 100%;
  }
  .rs-column.-small-col-2-1 {
    width: 46.1538461538%;
  }
  .rs-column.-small-col-2-2 {
    width: 100%;
  }
  .rs-column.-small-col-3-1 {
    width: 28.2051282051%;
  }
  .rs-column.-small-col-3-2 {
    width: 64.1025641026%;
  }
  .rs-column.-small-col-3-3 {
    width: 100%;
  }
  .rs-column.-small-col-4-1 {
    width: 19.2307692308%;
  }
  .rs-column.-small-col-4-2 {
    width: 46.1538461538%;
  }
  .rs-column.-small-col-4-3 {
    width: 73.0769230769%;
  }
  .rs-column.-small-col-4-4 {
    width: 100%;
  }
  .rs-column.-small-col-5-1 {
    width: 13.8461538462%;
  }
  .rs-column.-small-col-5-2 {
    width: 35.3846153846%;
  }
  .rs-column.-small-col-5-3 {
    width: 56.9230769231%;
  }
  .rs-column.-small-col-5-4 {
    width: 78.4615384615%;
  }
  .rs-column.-small-col-5-5 {
    width: 100%;
  }
  .rs-column.-small-col-6-1 {
    width: 10.2564102564%;
  }
  .rs-column.-small-col-6-2 {
    width: 28.2051282051%;
  }
  .rs-column.-small-col-6-3 {
    width: 46.1538461538%;
  }
  .rs-column.-small-col-6-4 {
    width: 64.1025641026%;
  }
  .rs-column.-small-col-6-5 {
    width: 82.0512820513%;
  }
  .rs-column.-small-col-6-6 {
    width: 100%;
  }
}

@keyframes bullet-active {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@keyframes bullet-inactive {
  from {
    width: 0%;
  }
  to {
    width: 0%;
  }
}
:root {
  --primary-color: #d3021d;
  --article-background: transparent;
}

body.website-biogast {
  --primary-color: #ee8300;
}

#shopcontent {
  margin-top: -38px;
}
#shopcontent .ce_headline {
  font-weight: bold;
}
#shopcontent h2.ce_headline {
  font-size: 35px;
}
@media only screen and (max-width: 768px) {
  #shopcontent h2.ce_headline {
    font-size: 22px;
  }
}
#shopcontent .ce_text {
  font-size: 20px;
}
@media only screen and (max-width: 768px) {
  #shopcontent .ce_text {
    font-size: inherit;
  }
}
#shopcontent .slick-arrow,
#shopcontent .swiper-button-prev,
#shopcontent .swiper-button-next {
  width: 76px;
  height: 76px;
  background-size: 100% auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  transition: 0.4s;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
}
#shopcontent .slick-arrow:after, #shopcontent .slick-arrow:before,
#shopcontent .swiper-button-prev:after,
#shopcontent .swiper-button-prev:before,
#shopcontent .swiper-button-next:after,
#shopcontent .swiper-button-next:before {
  display: none;
}
@media only screen and (max-width: 1040px) {
  #shopcontent .slick-arrow,
#shopcontent .swiper-button-prev,
#shopcontent .swiper-button-next {
    width: 54px;
    height: 54px;
  }
}
@media only screen and (max-width: 768px) {
  #shopcontent .slick-arrow,
#shopcontent .swiper-button-prev,
#shopcontent .swiper-button-next {
    width: 32px;
    height: 32px;
    display: none !important;
  }
}
#shopcontent .slick-next,
#shopcontent .swiper-button-next {
  right: 0;
  background-image: url("../images/slider-arrow-right.svg");
}
#shopcontent .slick-prev,
#shopcontent .swiper-button-prev {
  left: 0;
  background-image: url("../images/slider-arrow-left.svg");
}
#shopcontent .slick-arrow.slick-disabled,
#shopcontent .swiper-button-prev.swiper-button-disabled,
#shopcontent .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  visibility: hidden;
}
#shopcontent .swiper-container {
  overflow: visible;
}
#shopcontent .swiper-pagination {
  display: flex;
  justify-content: center;
  bottom: 12px;
}
#shopcontent .swiper-pagination-bullet {
  width: 126px;
  height: 2px;
  background-color: #c1c1c1;
  display: block;
  margin: 0 18px;
  border-radius: 0;
  position: relative;
  opacity: 1;
}
@media only screen and (max-width: 768px) {
  #shopcontent .swiper-pagination-bullet {
    width: 60px;
    margin: 0 9px;
  }
}
#shopcontent .swiper-pagination-bullet:after {
  content: "";
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0%;
  background-color: black;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-duration: 0ms;
  animation-name: bullet-inactive;
}
#shopcontent .swiper-pagination-bullet-active {
  background-color: #c1c1c1;
  opacity: 1;
}
#shopcontent .swiper-pagination-bullet-active:after {
  animation-duration: 6500ms;
  animation-name: bullet-active;
}

.mod_article {
  margin-left: 130px;
  margin-right: 130px;
}
@media only screen and (max-width: 1040px) {
  .mod_article {
    margin-left: 0;
    margin-right: 0;
  }
}
.mod_article > .inside {
  max-width: 2240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.mod_article.full-width {
  margin-left: -30px;
  margin-right: -30px;
}
@media only screen and (max-width: 870px) {
  .mod_article.full-width {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.mod_article.full-width > .inside {
  max-width: 2560px;
}

/**
 * shop slide
 */
.ce_rsce_shop_slide {
  overflow: hidden;
}
.ce_rsce_shop_slide img {
  width: 100%;
  height: 415px;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
@media only screen and (max-width: 768px) {
  .ce_rsce_shop_slide img {
    object-fit: fill;
    height: auto;
    width: auto;
    font-family: "object-fit: fill;";
  }
}
.ce_rsce_shop_slide > .inside {
  position: relative;
}
.ce_rsce_shop_slide .text-container {
  position: absolute;
  left: 160px;
  transition: transform 0.6s 0.7s;
  bottom: 75px;
  width: 386px;
  background-color: white;
  box-sizing: border-box;
  padding: 20px 20px 22px 20px;
}
.ce_rsce_shop_slide .text-container .headline {
  margin: 0;
  font-size: 25px;
  margin-bottom: 6px;
}
.ce_rsce_shop_slide .text-container .text {
  font-size: 30px;
  min-height: 56px;
  line-height: 0.9;
  margin-bottom: 10px;
}
.ce_rsce_shop_slide .text-container .text p {
  margin: 0;
}
@media only screen and (max-width: 1040px) {
  .ce_rsce_shop_slide .text-container {
    left: 30px;
  }
}
@media only screen and (max-width: 870px) {
  .ce_rsce_shop_slide .text-container {
    left: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .ce_rsce_shop_slide .text-container {
    position: static;
    width: auto;
    transform: none;
    background-color: transparent;
    padding-left: 15px;
    padding-right: 15px;
  }
  .ce_rsce_shop_slide .text-container .headline {
    font-size: 22px;
  }
  .ce_rsce_shop_slide .text-container .text {
    font-size: 26px;
  }
}
.ce_rsce_shop_slide .button-container {
  display: flex;
}
.ce_rsce_shop_slide .button-container a {
  display: block;
  font-size: 18px;
  line-height: 22px;
  padding: 8px 10px;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}
.ce_rsce_shop_slide .button-container a:hover {
  color: white !important;
}
.ce_rsce_shop_slide.slick-slide .text-container, .ce_rsce_shop_slide.swiper-slide .text-container {
  transform: translateX(-560px);
}
.ce_rsce_shop_slide.slick-active .text-container, .ce_rsce_shop_slide.swiper-slide-active .text-container {
  transform: translateX(0);
}

/**
 * products-slider
 */
ff-campaign-pushed-products,
.ff-magento-product-list {
  position: relative;
}

.products-slider {
  padding: 0;
  margin: 42px 0;
  margin-right: -100%;
  background-color: transparent;
  box-shadow: none;
  display: block;
  position: static;
}
.products-slider .products,
.products-slider .slick-slider {
  position: static;
}
.products-slider .products:after, .products-slider .products:before,
.products-slider .slick-slider:after,
.products-slider .slick-slider:before {
  display: none;
}
.products-slider .slick-list {
  overflow: visible;
}
.products-slider .products[data-mode=grid] [data-product], .products-slider .products-slider .products [data-product] {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  width: 205px;
}
.products-slider .products[data-mode=grid] {
  margin-left: 0;
  margin-right: 0;
  flex-wrap: nowrap;
}
.products-slider .products[data-mode=grid] [data-product] {
  margin-left: 0;
  margin-right: 15px;
  flex-basis: auto;
}
.products-slider.swiper-container {
  overflow: visible;
  width: 100%;
}

/**
 * image slider
 */
.ce_rsce_swiper_start.image-slider,
.ce_swiperStart.image-slider {
  font-weight: bold;
}
.ce_rsce_swiper_start.image-slider .slick-arrow,
.ce_rsce_swiper_start.image-slider .swiper-button-prev,
.ce_rsce_swiper_start.image-slider .swiper-button-next,
.ce_swiperStart.image-slider .slick-arrow,
.ce_swiperStart.image-slider .swiper-button-prev,
.ce_swiperStart.image-slider .swiper-button-next {
  position: absolute;
  top: -32px;
  bottom: 32px;
  margin: auto;
}
.ce_rsce_swiper_start.image-slider .slick-list,
.ce_swiperStart.image-slider .slick-list {
  overflow: visible;
}
.ce_rsce_swiper_start.image-slider .slick-next,
.ce_swiperStart.image-slider .slick-next {
  right: 0;
}
.ce_rsce_swiper_start.image-slider .swiper-wrapper .ce_text,
.ce_swiperStart.image-slider .swiper-wrapper .ce_text {
  margin-right: 20px;
  width: 270px;
}
.ce_rsce_swiper_start.image-slider .swiper-container,
.ce_swiperStart.image-slider .swiper-container {
  overflow: visible;
  width: 100%;
}

/* first slider */
#shopcontent .ce_rsce_swiper_start.first .swiper-button-prev,
#shopcontent .ce_swiperStart.first .swiper-button-prev,
#shopcontent .ce_swiperStart:first-child .swiper-button-prev {
  left: 36px;
}
#shopcontent .ce_rsce_swiper_start.first .swiper-button-next,
#shopcontent .ce_swiperStart.first .swiper-button-next,
#shopcontent .ce_swiperStart:first-child .swiper-button-next {
  right: 36px;
}

/* rsce_menu_band */
.menu-band {
  background-color: white;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
}
.menu-band__items {
  display: flex;
  justify-content: center;
  gap: 31px;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 768px) {
  .menu-band__items {
    display: none;
    padding: 2em 3em;
  }
}
@media only screen and (max-width: 768px) {
  .menu-band__item {
    border-top: 1px solid black;
  }
  .menu-band__item:first-child {
    border-top: 0;
  }
}
.menu-band__link {
  display: block;
  text-decoration: none;
  color: inherit;
  height: 45px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  padding: 0 9.5px;
  position: relative;
}
.menu-band__link:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 2px solid var(--primary-color);
  transform: scaleX(0);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
}
.menu-band__link:hover {
  color: var(--primary-color);
}
.menu-band__link:hover:after {
  transform: scaleX(1);
  opacity: 1;
}
@media only screen and (max-width: 768px) {
  .menu-band__link {
    font-size: 1.2em;
    padding: 0.8em 0;
    height: auto;
  }
  .menu-band__link:after {
    display: none;
  }
  .menu-band__link:hover {
    color: inherit;
  }
}
.menu-band__button-container {
  display: none;
}
@media only screen and (max-width: 768px) {
  .menu-band__button-container {
    display: block !important;
  }
}
.menu-band__button {
  display: block;
  cursor: pointer;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 100%;
  height: 45px;
  text-align: center;
  font-size: 1.1em;
}
.menu-band__button:after {
  background-image: url("../images/Dropdown_Pfeil.svg");
  background-size: 100% auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.1em;
  height: 1.1em;
  content: "";
  display: block;
  position: absolute;
  right: 1.1em;
  top: 0;
  bottom: 0;
  margin: auto;
}
@media only screen and (max-width: 768px) {
  .menu-band.show .menu-band {
    overflow: hidden;
    box-shadow: none;
  }
  .menu-band.show .menu-band__button-container {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  }
  .menu-band.show .menu-band__items {
    display: block;
  }
}

/* Margins (https://gitlab.com/inspiredminds/kastner-website-v2/-/issues/251) */
.margin--0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.margin--top--0 {
  margin-top: 0rem !important;
}

.margin--bottom--0 {
  margin-bottom: 0rem !important;
}

.margin--1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.margin--top--1 {
  margin-top: 1rem !important;
}

.margin--bottom--1 {
  margin-bottom: 1rem !important;
}

.margin--2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.margin--top--2 {
  margin-top: 2rem !important;
}

.margin--bottom--2 {
  margin-bottom: 2rem !important;
}

.margin--3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.margin--top--3 {
  margin-top: 3rem !important;
}

.margin--bottom--3 {
  margin-bottom: 3rem !important;
}

.margin--4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.margin--top--4 {
  margin-top: 4rem !important;
}

.margin--bottom--4 {
  margin-bottom: 4rem !important;
}

.margin--6 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.margin--top--6 {
  margin-top: 6rem !important;
}

.margin--bottom--6 {
  margin-bottom: 6rem !important;
}

.margin--8 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.margin--top--8 {
  margin-top: 8rem !important;
}

.margin--bottom--8 {
  margin-bottom: 8rem !important;
}

/* Article background (https://gitlab.com/inspiredminds/kastner-website-v2/-/issues/251) */
.mod_article.bgcolor {
  position: relative;
  padding-top: 1px;
  padding-bottom: 1px;
}
.mod_article.bgcolor > * {
  position: relative;
}
.mod_article.bgcolor:before {
  content: "";
  display: block;
  position: absolute;
  left: -200%;
  right: -200%;
  top: 0;
  bottom: 0;
  background-color: var(--article-background);
}

/* Text color (https://gitlab.com/inspiredminds/kastner-website-v2/-/issues/251) */
[class^=ce_].custom-text-color {
  color: var(--text-color);
}