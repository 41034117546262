/* global settings */
@import "settings/_colors.scss";
@import "settings/_layout.scss";

@import "framework/_constants";
@import "functions/_mixins";
@import "framework/_columns";


@keyframes bullet-active {
    from { width: 0%; }
    to { width: 100%; }
}

@keyframes bullet-inactive {
    from { width: 0%; }
    to { width: 0%; }
}

:root {
    --primary-color: #{$kastner-red};
    --article-background: transparent;
}

body.website-biogast {
    --primary-color: #{$biogast-orange};
}

#shopcontent {
    margin-top: -38px;

    .ce_headline {
        font-weight: bold;
    }

    h2.ce_headline {
        font-size: 35px;

        @include smartphone()
        {
            font-size: 22px;
        }
    }

    .ce_text {
        font-size: 20px;

        @include smartphone()
        {
            font-size: inherit;
        }
    }

    .slick-arrow,
    .swiper-button-prev,
    .swiper-button-next {
        width: 76px;
        height: 76px;
        background-size: 100% auto;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: white;
        transition: 0.4s;
        margin: auto;
        top: 0;
        bottom: 0;
        border-radius: 50%;
        box-shadow: 0 0 50px 0 rgba(0,0,0,0.2);

        &:after, &:before {
            display: none;
        }

        @include tablet() {
            width: 54px;
            height: 54px;
        }

        @include smartphone() {
            width: 32px;
            height: 32px;
            display: none !important;
        }
    }

    .slick-next,
    .swiper-button-next {
        right: 0;
        background-image: url('../images/slider-arrow-right.svg');
    }

    .slick-prev,
    .swiper-button-prev {
        left: 0;
        background-image: url('../images/slider-arrow-left.svg');
    }

    .slick-arrow.slick-disabled,
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
        opacity: 0;
        visibility: hidden;
    }

    .swiper-container {
        overflow: visible;
    }

    .swiper-pagination {
        display: flex;
        justify-content: center;
        bottom: 12px;
    }

    .swiper-pagination-bullet {
        width: 126px;
        height: 2px;
        background-color: #c1c1c1;
        display: block;
        margin: 0 18px;
        border-radius: 0;
        position: relative;
        opacity: 1;

        @include smartphone() {
            width: 60px;
            margin: 0 9px;
        }

        &:after {
            content: '';
            display: block;
            height: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0%;
            background-color: black;
            animation-fill-mode: forwards;
            animation-timing-function: linear;
            animation-duration: 0ms;
            animation-name: bullet-inactive;
        }
    }

    .swiper-pagination-bullet-active {
        background-color: #c1c1c1;
        opacity: 1;

        &:after {
            animation-duration: 6500ms;
            animation-name: bullet-active;
        }
    }
}

.mod_article {
    margin-left: 130px;
    margin-right: 130px;

    @include tablet()
    {
        margin-left: 0;
        margin-right: 0;
    }

    & > .inside {
        max-width: 2240px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    &.full-width {
        @include main-bleed();

        & > .inside {
            max-width: 2560px;
        }
    }
}


/**
 * shop slide
 */
.ce_rsce_shop_slide {
    overflow: hidden;

    img {
        width: 100%;
        height: 415px;
        object-fit: cover;
        font-family: 'object-fit: cover;';

        @include smartphone()
        {
            object-fit: fill;
            height: auto;
            width: auto;
            font-family: 'object-fit: fill;';
        }
    }

    & > .inside {
        position:relative;
    }

    .text-container {
        position:absolute;
        left: 160px;
        transition: transform 0.6s 0.7s;
        bottom: 75px;
        width: 386px;
        background-color: white;
        box-sizing: border-box;
        padding: 20px 20px 22px 20px;

        .headline {
            margin: 0;
            font-size: 25px;
            margin-bottom: 6px;
        }

        .text {
            font-size: 30px;
            min-height: 56px;
            line-height: 0.9;
            margin-bottom: 10px;

            p {
                margin: 0;
            }
        }

        @include tablet()
        {
            left: 30px;
        }

        @include bp870()
        {
            left: 15px;
        }

        @include smartphone()
        {
            position: static;
            width: auto;
            transform: none;
            background-color: transparent;
            padding-left: 15px;
            padding-right: 15px;

            .headline {
                font-size: 22px;
            }

            .text {
                font-size: 26px;
            }
        }
    }

    .button-container {
        display: flex;

        a {
            display: block;
            font-size: 18px;
            line-height: 22px;
            padding: 8px 10px;
            border: 2px solid var(--primary-color);
            color: var(--primary-color);

            &:hover {
                color: white !important;
            }
        }
    }

    &.slick-slide,
    &.swiper-slide {

        .text-container {
            transform: translateX(-560px);
        }
    }

    &.slick-active,
    &.swiper-slide-active {

        .text-container {
            transform: translateX(0);
        }
    }
}


/**
 * products-slider
 */
ff-campaign-pushed-products,
.ff-magento-product-list {
    position: relative;
}

.products-slider {
    padding: 0;
    margin: 42px 0;
    margin-right: -100%;
    background-color: transparent;
    box-shadow: none;
    display: block;
    position: static;

    .products,
    .slick-slider {
        position: static;

        &:after,
        &:before {
            display: none;
        }
    }

    .slick-list {
        overflow: visible;
    }

    .products[data-mode="grid"] [data-product], .products-slider .products [data-product] {
        box-shadow: $box-shadow;
        width: 205px;
    }

    .products[data-mode="grid"] {
        margin-left: 0;
        margin-right: 0;
        flex-wrap: nowrap;
        
        [data-product] {
            margin-left: 0;
            margin-right: $inside-padding;
            flex-basis: auto;
        }
    }

    &.swiper-container {
        overflow: visible;
        width: 100%;
    }
}


/**
 * image slider
 */
.ce_rsce_swiper_start.image-slider,
.ce_swiperStart.image-slider {
    font-weight: bold;

    .slick-arrow,
    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        top: -32px;
        bottom: 32px;
        margin: auto;
    }

    .slick-list {
        overflow: visible;
    }

    .slick-next {
        right: 0;
    }

    .swiper-wrapper {

        .ce_text {
            margin-right: 20px;
            width: 270px;
        }
    }

    .swiper-container {
        overflow: visible;
        width: 100%;
    }
}


/* first slider */
#shopcontent .ce_rsce_swiper_start.first,
#shopcontent .ce_swiperStart.first,
#shopcontent .ce_swiperStart:first-child {
    .swiper-button-prev {
        left: 36px;
    }

    .swiper-button-next {
        right: 36px;
    }
}


/* rsce_menu_band */
.menu-band {
    background-color: white;
    box-shadow:0 3px 3px 0 rgba(0,0,0,0.2);

    &__items {
        display: flex;
        justify-content: center;
        gap: 31px;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;

        @include smartphone() {
            display: none;
            padding: 2em 3em;
        }
    }

    &__item {
        @include smartphone() {
            border-top: 1px solid black;

            &:first-child {
                border-top: 0;
            }
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        color: inherit;
        height: 45px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        padding: 0 9.5px;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-top: 2px solid var(--primary-color);
            transform: scaleX(0);
            opacity: 0;
            transition: transform 0.2s, opacity 0.2s;
        }

        &:hover {
            color: var(--primary-color);

            &:after {
                transform: scaleX(1);
                opacity: 1;
            }
        }

        @include smartphone() {
            font-size: 1.2em;
            padding: 0.8em 0;
            height: auto;

            &:after {
                display: none;
            }

            &:hover {
                color: inherit;
            }
        }
    }

    &__button-container {
        display: none;

        @include smartphone() {
            display: block !important;
        }
    }

    &__button {
        display: block;
        cursor: pointer;
        border: 0;
        outline: 0;
        margin: 0;
        padding: 0;
        background-color: transparent;
        position: relative;
        width: 100%;
        height: 45px;
        text-align: center;
        font-size: 1.1em;

        &:after {
            @include sprite('../images/Dropdown_Pfeil.svg');
            width: 1.1em;
            height: 1.1em;
            content: '';
            display: block;
            position: absolute;
            right: 1.1em;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    @include smartphone() {
        &.show {
            .menu-band {
                overflow: hidden;
                box-shadow: none;

                &__button-container {
                    box-shadow: 0 3px 3px 0 rgba(0,0,0,0.2);
                }

                &__items {
                    display: block;
                }
            }
        }
    }
}

/* Margins (https://gitlab.com/inspiredminds/kastner-website-v2/-/issues/251) */
@mixin margin-type($type, $margin, $important: false)
{
    @if $important
    {
        margin-#{$type}: #{$margin}rem !important;
    }
    @else
    {
        margin-#{$type}: #{$margin}rem;
    }
}

@mixin margin-generator($type, $margin)
{
    @if $type == ''
    {
        .margin--#{$margin} {
            @include margin-type('top', $margin, true);
            @include margin-type('bottom', $margin, true);
        }
    }
    @else
    {
        .margin--#{$type}--#{$margin} {
            @include margin-type($type, $margin, true);
        }
    }
}

@mixin margin($margin) {
    @include margin-generator('', $margin);
    @include margin-generator('top', $margin);
    @include margin-generator('bottom', $margin);
}

@include margin(0);
@include margin(1);
@include margin(2);
@include margin(3);
@include margin(4);
@include margin(6);
@include margin(8);

/* Article background (https://gitlab.com/inspiredminds/kastner-website-v2/-/issues/251) */
.mod_article {
    &.bgcolor {
        position: relative;
        padding-top: 1px;
        padding-bottom: 1px;

        & > * {
            position: relative;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: -200%;
            right: -200%;
            top: 0;
            bottom: 0;
            background-color: var(--article-background);
        }
    }
}

/* Text color (https://gitlab.com/inspiredminds/kastner-website-v2/-/issues/251) */
[class^="ce_"] {
    &.custom-text-color {
        color: var(--text-color);
    }
}
